import React, { useEffect } from "react"

import "./styles.css"
import { Link } from "gatsby"
import { useAnalytics } from "../../hooks/useTracking"

export const SolutionSectionTab = ({
  activeTab,
  open,
  setOpen,
  data,
  isActive,
}) => {
  const analytics = useAnalytics()
  const cardAmount = data?.cards?.length

  useEffect(() => {
    setOpen(false)
  }, [activeTab])

  return (
    <div
      className={`solution-section-tab__wrapper ${isActive ? "active" : ""}`}
    >
      <div className="solution-section-tab" id="solution-section-tab">
        <div className="solution-section-tab__content">
          <h3 className="solution-section-tab__title">{data?.title}</h3>
          <p className="solution-section-tab__about">{data?.about}</p>
          <div className="solution-section-tab__tricks">
            <div className="solution-section-tab__tricks-1">
              {data?.tricks?.slice(0, 3).map(trick => (
                <div key={trick} className="solution-section-tab__trick">
                  <span>
                    <svg
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.76105 9.54995C4.43275 9.54995 4.10445 9.43089 3.85383 9.19136L0.37593 5.86737C-0.12531 5.38831 -0.12531 4.61229 0.37593 4.13464C0.87717 3.65558 1.68765 3.65418 2.1889 4.13323L4.76105 6.59156L10.8111 0.809245C11.3123 0.330187 12.1228 0.330187 12.6241 0.809245C13.1253 1.2883 13.1253 2.06432 12.6241 2.54338L5.66826 9.19136C5.41764 9.43089 5.08935 9.54995 4.76105 9.54995Z"
                        fill="#3D50DF"
                      />
                    </svg>
                  </span>
                  <div className="solution-section-tab__trick__text">
                    {trick}
                  </div>
                </div>
              ))}
            </div>
            <div className="solution-section-tab__tricks-2">
              {data?.tricks?.slice(3).map(trick => (
                <div key={trick} className="solution-section-tab__trick">
                  <span>
                    <svg
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.76105 9.54995C4.43275 9.54995 4.10445 9.43089 3.85383 9.19136L0.37593 5.86737C-0.12531 5.38831 -0.12531 4.61229 0.37593 4.13464C0.87717 3.65558 1.68765 3.65418 2.1889 4.13323L4.76105 6.59156L10.8111 0.809245C11.3123 0.330187 12.1228 0.330187 12.6241 0.809245C13.1253 1.2883 13.1253 2.06432 12.6241 2.54338L5.66826 9.19136C5.41764 9.43089 5.08935 9.54995 4.76105 9.54995Z"
                        fill="#3D50DF"
                      />
                    </svg>
                  </span>
                  <div className="solution-section-tab__trick__text">
                    {trick}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="solution-section-tab__more"
            onClick={() => setOpen(prevState => !prevState)}
          >
            <div className="solution-section-tab__more__text">
              Explore the {data?.title?.toLowerCase()}
            </div>
            <div
              className={`solution-section-tab__more__icon ${
                open ? "open" : ""
              }`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.99982 12C1.99982 17.515 6.48582 22 11.9998 22C17.5138 22 21.9998 17.515 21.9998 12C21.9998 6.486 17.5138 2 11.9998 2C6.48582 2 1.99982 6.486 1.99982 12"
                  stroke="#3D50DF"
                />
                <path
                  d="M7.77911 10.5575C7.77911 10.7485 7.85211 10.9405 7.99811 11.0865L11.4681 14.5735C11.6091 14.7145 11.8001 14.7935 12.0001 14.7935C12.1991 14.7935 12.3901 14.7145 12.5311 14.5735L16.0031 11.0865C16.2951 10.7935 16.2951 10.3195 16.0011 10.0265C15.7071 9.73448 15.2321 9.73548 14.9401 10.0285L12.0001 12.9815L9.06011 10.0285C8.76811 9.73548 8.29411 9.73448 8.00011 10.0265C7.85211 10.1725 7.77911 10.3655 7.77911 10.5575Z"
                  fill="#3D50DF"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="solution-section-tab__img">
          <img src={data?.img} alt={data?.title} />
        </div>
      </div>

      <div className={`solution-section-tab__expanded ${open ? "open" : ""}`}>
        <div className="solution-section-tab__expanded__content">
          <div className="solution-section-tab__description">
            {data?.description}
          </div>
          <div className="solution-section-tab__action">
            <Link onClick={analytics.tryForFreeClicked} to="/pricing">
              <button className="wt-button wt-button--red-blue">
                Try for free
              </button>
            </Link>
          </div>
        </div>
        <div
          className={`solution-section-tab__cards-${
            cardAmount > 2 ? "1" : "2"
          }`}
        >
          {data?.cards?.map(card => (
            <div key={card?.title} className="solution-section-tab__card">
              <div className="solution-section-tab__card__icon">
                <img src={card?.icon} alt={card?.title} />
              </div>
              <h4 className="solution-section-tab__card__title">
                {card?.title}
              </h4>
              <p className="solution-section-tab__card__description">
                {card?.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
